import { func } from "prop-types";

import { Link } from "gatsby";
import { useMapUrlId } from "../../common/mapUrl.js";
import { useMapScroll } from "../MapScroll/mapScrollContext.jsx";

const propTypes = {
  onClick: func,
};

export const MapLink = (props) => {
  const { onClick, ...otherProps } = props;

  const mapId = useMapUrlId();
  const mapScrollContext = useMapScroll();

  return (
    <Link
      to={`/map/${mapId || ""}`}
      onClick={(e) => {
        // call consumer onClick first
        onClick && onClick(e);

        // if we're on the map page, scroll to the map info panel
        if (mapScrollContext) {
          e.preventDefault();
          mapScrollContext.scrollToInfoPanel();
        }
      }}
      {...otherProps}
    />
  );
};

MapLink.propTypes = propTypes;
export default MapLink;
