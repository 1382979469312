import * as R from "ramda";
import { node, number, oneOf, shape, string, array } from "prop-types";
import { graphql } from "gatsby";
import GatsbyImage from "gatsby-image";

import * as types from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";
const { BLOCKS, INLINES } = types;

import ContentfulLink from "../components/ContentfulLink/ContentfulLink.jsx";
import Container5050 from "../components/Container/Container5050.jsx";
import Container7030 from "../components/Container/Container7030.jsx";
import Container3070 from "../components/Container/Container3070.jsx";
import InfoTooltip from "../components/InfoTooltip/InfoTooltip.jsx";

const createSlug = (children) => {
  //in case there are nested tags
  //so this is annoying and pretty fragile because contentful throws out a lot of different values for `children` depending on if there are additional elements nested inside the heading
  //if just <h3>text</h3>, children will be ["text"], if <h3><b>text</b></h3>, children will be [object{...props.children:"text"}]
  //if there's an extra line break above "text" inside the <h3>, it'll children will be ["/n", object{...props.children:"text"}]

  if (children.length === 1 && typeof children[0] === "string") {
    return children[0]
      .toLowerCase()
      .trim()
      .replace(/[^\w ]+/g, "")
      .replace(/ +/g, "-");
  } else {
    const text = children.filter((child) => typeof child === "object")[0];
    const sourceText =
      typeof text === "string"
        ? text
        : typeof text === "object"
        ? text.props.children
        : `section-${Math.random()}`;

    return sourceText
      .toLowerCase()
      .trim()
      .replace(/[^\w ]+/g, "")
      .replace(/ +/g, "-");
  }
};
// !! __typename and contentful_id should be in each one of these so we can differentiat in extractRichText!
export const richTextReferencesFragment = graphql`
  fragment RichTextReferences on Node {
    ... on ContentfulAsset {
      __typename
      contentful_id
      title
      description
      fluid {
        base64
        aspectRatio
        src
        srcSet
        srcWebp
        srcSetWebp
        sizes
      }
      fixed {
        src
        srcSet
        width
        height
        srcSetWebp
        srcWebp
        tracedSVG
      }
      file {
        url
        fileName
        details {
          size
          image {
            height
            width
          }
        }
      }
    }
    ... on ContentfulTwoUp {
      __typename
      contentful_id
      split
      left {
        raw
      }
      right {
        raw
      }
    }
    ... on ContentfulInfoTooltip {
      __typename
      contentful_id
      label
      tooltipContent {
        raw
      }
    }
  }
`;

/**
 * extractRichText
 * https://www.contentful.com/developers/docs/tutorials/general/rich-text-and-gatsby/
 * @param  {Object} node graphql node
 * @return {Node} React node
 */
export const extractRichText = (node) => {
  if (node) {
    return renderRichText(node, {
      renderNode: {
        [BLOCKS.HEADING_1]: (node, children) => {
          return (
            <h1 tabIndex="-1" id={createSlug(children)}>
              {children}
            </h1>
          );
        },
        [BLOCKS.HEADING_2]: (node, children) => {
          return (
            <h2 tabIndex="-1" id={createSlug(children)}>
              {children}
            </h2>
          );
        },
        [BLOCKS.HEADING_3]: (node, children) => {
          return (
            <h3 tabIndex="-1" id={createSlug(children)}>
              {children}
            </h3>
          );
        },
        [BLOCKS.HEADING_4]: (node, children) => {
          return (
            <h4 tabIndex="-1" id={createSlug(children)}>
              {children}
              {children}
            </h4>
          );
        },
        [BLOCKS.EMBEDDED_ENTRY]: (entry) => {
          const { target } = entry.data;
          if (!target) {
            console.warn(
              "no target!",
              R.over(R.lensProp("raw"), JSON.parse)(node),
              entry,
            );
            return "";
          }

          switch (target.__typename) {
            case "ContentfulInternalLink":
            case "ContentfulExternalLink":
            case "ContentfulMediaLink":
            case "ContentfulPageLink": {
              const link = extractLink(entry.data.target);
              return <ContentfulLink className="btn" {...link} />;
            }
            case "ContentfulTwoUp": {
              const left = extractRichText(target.left);
              const right = extractRichText(target.right);

              switch (target.split) {
                case "50-50":
                  return <Container5050 left={left} right={right} />;
                case "70-30":
                  return <Container7030 left={left} right={right} />;
                case "30-70":
                  return <Container3070 left={left} right={right} />;
              }
            }
          }

          return "";
        },
        [INLINES.EMBEDDED_ENTRY]: (entry) => {
          const { target } = entry.data;

          if (!target) {
            console.warn(
              "no target!",
              R.over(R.lensProp("raw"), JSON.parse)(node),
              entry,
            );
            return "";
          }

          switch (target.__typename) {
            case "ContentfulInternalLink":
            case "ContentfulExternalLink":
            case "ContentfulMediaLink":
            case "ContentfulPageLink": {
              const link = extractLink(entry.data.target);
              return <ContentfulLink {...link} />;
            }
            case "ContentfulInfoTooltip": {
              const children = extractRichText(target.tooltipContent);

              return (
                <span style={{ display: "inline-block" }}>
                  {target.label}
                  <InfoTooltip>{children}</InfoTooltip>
                </span>
              );
            }
          }

          return "";
        },
        [BLOCKS.EMBEDDED_ASSET]: (asset) => {
          if (asset.data.target && asset.data.target.fluid) {
            return <GatsbyImage fixed={asset.data.target.fixed} />;
          } else if (asset.data.target && asset.data.target.file.url) {
            return (
              <a
                href={asset.data.target.file.url}
                target="_blank"
                rel="noopener noreferrer"
                className="contentful-link btn image-block__link"
                aria-label={`Download ${asset.data.target.title} (PDF)`}
                download
              >
                <span className="contentful-link__block">
                  Download {asset.data.target.title} (PDF)
                </span>
                <svg width="10" height="14" viewBox="0 0 10 14">
                  <g fill="currentColor">
                    <path d="M4.005.19h1.99v9.91h-1.99zM.045 11.57h9.91v1.99H.045z"></path>
                    <path d="M3.593 10.186L8.4 5.378l1.407 1.407L5 11.593z"></path>
                    <path d="M.192 6.785l1.407-1.407 4.808 4.808L5 11.593z"></path>
                  </g>
                </svg>
              </a>

              // <iframe
              //   className="iframe"
              //   style={{ width: "100%", height: "900px" }}
              //   title={asset.data.target.file.fileName}
              //   src={asset.data.target.file.url}
              // />
            );
          }
        },
      },
    });
  }

  return node;
};

/**
 * Link Fragment
 * This will be available in any graphql query!!
 * https://www.gatsbyjs.com/docs/reference/graphql-data-layer/using-graphql-fragments/
 * see https://medium.com/@Zepro/contentful-reference-fields-with-gatsby-js-graphql-9f14ed90bdf9
 * contentful_id needed for renderRichText above
 */
export const linkFragment = graphql`
  fragment Link on Node {
    ... on ContentfulInternalLink {
      contentful_id
      linkText
      linkUrl
    }
    ... on ContentfulMediaLink {
      contentful_id
      linkText
      linkMedia {
        file {
          url
        }
      }
    }
    ... on ContentfulExternalLink {
      contentful_id
      linkUrl
      linkText
      linkDescription
    }
    ... on ContentfulPageLink {
      contentful_id
      linkText
      linkDescription
      page {
        url
      }
    }
  }
`;

/**
 * extractLink
 * To be used with <ContentfulLink {...link} />
 *
 * !! important !! use the ...Link fragment above!
 *
 * {
 *   link {
 *     ...Link
 *   }
 * }
 */
export const extractLink = R.compose(
  R.cond([
    [
      R.propEq("__typename", "ContentfulPageLink"),
      R.applySpec({
        children: R.prop("linkText"),
        //we need to check for a slash on page link urls, the rich text page does not have one.
        to: R.compose(R.replace(/^([^\/])/, "/$1"), R.path(["page", "url"])),
        description: R.prop("linkDescription"),
        type: () => "page",
      }),
    ],
    [
      R.propEq("__typename", "ContentfulMediaLink"),
      R.applySpec({
        children: R.prop("linkText"),
        to: R.path(["linkMedia", "file", "url"]),
        description: R.prop("linkDescription"),
        type: () => "media",
      }),
    ],
    [
      R.propEq("__typename", "ContentfulExternalLink"),
      R.applySpec({
        children: R.prop("linkText"),
        to: R.prop("linkUrl"),
        description: R.prop("linkDescription"),
        type: () => "external",
      }),
    ],
    [
      R.propEq("__typename", "ContentfulInternalLink"),
      R.applySpec({
        children: R.prop("linkText"),
        to: R.prop("linkUrl"),
        description: R.prop("linkDescription"),
        type: () => "internal",
      }),
    ],
  ]),
  R.defaultTo({}),
);

export const linkFields = {
  children: node.isRequired,
  className: string,
  description: string,
  type: oneOf(["internal", "external", "media"]).isRequired,
  to: string.isRequired,
};

/**
 * extract a banner out of a contentful graphql query
 *
 * usage:
 *   const data = R.compose(
 *     R.evolve({
 *       topHeroBanner: extractBanner,
 *       bottomBanner: extractBanner,
 *     }),
 *     R.prop("contentfulMapPage"),
 *   )(contentfulData);
 *
 * To be used with a Banner component
 * eg.
 *    <Banner {...topHeroBanner} />
 *
 * @param  {Object} obj a contentful graphql object that is a "banner"
 * @return {Object} { title, subtitle, fluidBackground, backgroundUrl, content, link }
 */
export const extractBanner = R.compose(
  R.applySpec({
    title: R.compose(extractRichText, R.prop("title")),
    subtitle: R.compose(extractRichText, R.prop("subtitle")),
    fluidBackground: R.path(["backgroundImage", "fluid"]),
    backgroundSize: R.compose(R.toLower, R.propOr("", "backgroundSize")),
    content: R.compose(extractRichText, R.prop("content")),
    textColor: R.prop("textColor"),
    link: R.compose(extractLink, R.prop("link")),
  }),
  R.defaultTo({}),
);

export const extractDatasetInfo = R.compose(
  R.applySpec({
    buttonText: R.prop("buttonText"),
    content: R.compose(extractRichText, R.prop("content")),
  }),
  R.defaultTo({}),
);

export const extractImageBlocks = R.compose(
  R.map(
    R.applySpec({
      title: R.prop("title"),
      text: R.compose(extractRichText, R.prop("text")),
      link: R.compose(extractLink, R.prop("link")),
      fluidImage: R.path(["image", "fluid"]),
    }),
  ),
  R.defaultTo([]),
);

export const extractResourceItem = R.compose(
  R.map(
    R.applySpec({
      title: R.prop("title"),
      text: R.compose(extractRichText, R.prop("description")),
      link: R.compose(extractLink, R.prop("link")),
      fluidImage: R.path(["featuredImage", "fluid"]),
      tags: R.compose(
        R.values,
        R.map(R.join(", ")),
        R.map(R.pluck("name")),
        R.groupBy(R.prop("__typename")),
        R.prop("resourceTags"),
      ),
    }),
  ),
  R.defaultTo([]),
);

export const imageBlockFields = {
  title: string.isRequired,
  text: string, // html
  link: shape(linkFields),
  fluidImage: shape({
    aspectRatio: number.isRequired,
    base64: string.isRequired,
    sizes: string.isRequired,
    src: string.isRequired,
    srcSetWebp: string.isRequired,
    srcWebp: string.isRequired,
  }).isRequired,
};

export const extractTextBlock = R.compose(
  R.applySpec({
    title: R.prop("title"),
    text: R.compose(extractRichText, R.prop("text")),
  }),
  R.defaultTo({}),
);

export const extractInfoTooltip = R.compose(
  // if we have an infoTooltip, extract the rich text tooltipContent
  R.unless(R.isNil, R.over(R.lensProp("tooltipContent"), extractRichText)),
  R.prop("infoTooltip"),
);
