import { useState } from "react";
import { node, number } from "prop-types";
import Popup from "../Popup/Popup.jsx";
import useClickAway from "../../common/useClickAway.js";

import "./info-tooltip.scss";

const propTypes = {
  iconSize: number,
  children: node,
};

const defaultProps = {
  iconSize: 18,
};

const InfoTooltip = (props) => {
  const { children, iconSize, ...otherProps } = props;

  const [isOpen, setOpen] = useState(false);

  const containerRef = useClickAway({ isOpen, setOpen });

  return (
    <span className="info-tooltip" ref={containerRef}>
      <button
        type="button"
        className="info-tooltip__icon"
        style={iconSize ? { height: iconSize, width: iconSize } : {}}
        onClick={() => setOpen((open) => !open)}
        aria-label="open tooltip"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          viewBox="0 0 24 24"
          className="feather feather-info"
        >
          <circle
            cx="12"
            cy="12"
            r="10"
            strokeWidth="0"
            fill="#acacac"
          ></circle>
          <g
            fill="none"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <line x1="12" y1="16" x2="12" y2="12"></line>
            <line x1="12" y1="8" x2="12.01" y2="8"></line>
          </g>
        </svg>
      </button>
      <Popup
        isHidden={!isOpen}
        top={iconSize / 2}
        left={iconSize / 2}
        offset={iconSize / 2}
        width={300}
        selectable
        nudgePadding={5}
        nudgeToContain="viewport"
        {...otherProps}
      >
        <div className="info-tooltip__content fancy-a">{children}</div>
      </Popup>
    </span>
  );
};

InfoTooltip.defaultProps = defaultProps;
InfoTooltip.propTypes = propTypes;
export default InfoTooltip;
