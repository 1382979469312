import * as R from "ramda";

// id is FIPS code, and should match id in ./states-10m.json from https://github.com/topojson/us-atlas
export const states = [
  {
    id: "01",
    name: "Alabama",
    abbreviation: "AL",
  },
  {
    id: "02",
    name: "Alaska",
    abbreviation: "AK",
    isConus: false,
  },
  {
    id: "04",
    name: "Arizona",
    abbreviation: "AZ",
  },
  {
    id: "05",
    name: "Arkansas",
    abbreviation: "AR",
  },
  {
    id: "06",
    name: "California",
    abbreviation: "CA",
  },
  {
    id: "08",
    name: "Colorado",
    abbreviation: "CO",
  },
  {
    id: "09",
    name: "Connecticut",
    abbreviation: "CT",
  },
  {
    id: "10",
    name: "Delaware",
    abbreviation: "DE",
  },
  {
    id: "11",
    name: "District Of Columbia",
    abbreviation: "DC",
  },
  {
    id: "12",
    name: "Florida",
    abbreviation: "FL",
  },
  {
    id: "13",
    name: "Georgia",
    abbreviation: "GA",
  },
  {
    id: "15",
    name: "Hawaii",
    abbreviation: "HI",
    isConus: false,
  },
  {
    id: "16",
    name: "Idaho",
    abbreviation: "ID",
  },
  {
    id: "17",
    name: "Illinois",
    abbreviation: "IL",
  },
  {
    id: "18",
    name: "Indiana",
    abbreviation: "IN",
  },
  {
    id: "19",
    name: "Iowa",
    abbreviation: "IA",
  },
  {
    id: "20",
    name: "Kansas",
    abbreviation: "KS",
  },
  {
    id: "21",
    name: "Kentucky",
    abbreviation: "KY",
  },
  {
    id: "22",
    name: "Louisiana",
    abbreviation: "LA",
  },
  {
    id: "23",
    name: "Maine",
    abbreviation: "ME",
  },
  {
    id: "24",
    name: "Maryland",
    abbreviation: "MD",
  },
  {
    id: "25",
    name: "Massachusetts",
    abbreviation: "MA",
  },
  {
    id: "26",
    name: "Michigan",
    abbreviation: "MI",
  },
  {
    id: "27",
    name: "Minnesota",
    abbreviation: "MN",
  },
  {
    id: "28",
    name: "Mississippi",
    abbreviation: "MS",
  },
  {
    id: "29",
    name: "Missouri",
    abbreviation: "MO",
  },
  {
    id: "30",
    name: "Montana",
    abbreviation: "MT",
  },
  {
    id: "31",
    name: "Nebraska",
    abbreviation: "NE",
  },
  {
    id: "32",
    name: "Nevada",
    abbreviation: "NV",
  },
  {
    id: "33",
    name: "New Hampshire",
    abbreviation: "NH",
  },
  {
    id: "34",
    name: "New Jersey",
    abbreviation: "NJ",
  },
  {
    id: "35",
    name: "New Mexico",
    abbreviation: "NM",
  },
  {
    id: "36",
    name: "New York",
    abbreviation: "NY",
  },
  {
    id: "37",
    name: "North Carolina",
    abbreviation: "NC",
  },
  {
    id: "38",
    name: "North Dakota",
    abbreviation: "ND",
  },
  {
    id: "39",
    name: "Ohio",
    abbreviation: "OH",
  },
  {
    id: "40",
    name: "Oklahoma",
    abbreviation: "OK",
  },
  {
    id: "41",
    name: "Oregon",
    abbreviation: "OR",
  },
  {
    id: "42",
    name: "Pennsylvania",
    abbreviation: "PA",
  },
  {
    id: "44",
    name: "Rhode Island",
    abbreviation: "RI",
  },
  {
    id: "45",
    name: "South Carolina",
    abbreviation: "SC",
  },
  {
    id: "46",
    name: "South Dakota",
    abbreviation: "SD",
  },
  {
    id: "47",
    name: "Tennessee",
    abbreviation: "TN",
  },
  {
    id: "48",
    name: "Texas",
    abbreviation: "TX",
  },
  {
    id: "49",
    name: "Utah",
    abbreviation: "UT",
  },
  {
    id: "50",
    name: "Vermont",
    abbreviation: "VT",
  },
  {
    id: "51",
    name: "Virginia",
    abbreviation: "VA",
  },
  {
    id: "53",
    name: "Washington",
    abbreviation: "WA",
  },
  {
    id: "54",
    name: "West Virginia",
    abbreviation: "WV",
  },
  {
    id: "55",
    name: "Wisconsin",
    abbreviation: "WI",
  },
  {
    id: "56",
    name: "Wyoming",
    abbreviation: "WY",
  },
];

// undefined is true!
export const conusStates = R.reject(R.propEq("isConus", false), states);

/**
 * lookupStateName
 * @param  {String} twoLetters a (maybe valid) abbreviation from the list above
 * @return {String} the name of the state, or the input value if a state isn't found
 */
export const lookupStateName = (twoLetters) => {
  // ORI's use NB even though Nebraska is NE
  const abbreviation = twoLetters === "NB" ? "NE" : twoLetters;

  return R.compose(
    R.defaultTo(abbreviation),
    R.prop("name"),
    R.find(R.propEq("abbreviation", abbreviation)),
  )(states);
};
/**
 * lookupStateAbbreviation
 * @param  {String} name a (maybe valid) name from the list above
 * @return {String} the abbreviation of the state, or the input value if a state isn't found
 */
export const lookupStateAbbreviation = (name) =>
  R.compose(
    R.defaultTo(name),
    R.prop("abbreviation"),
    R.find(R.propEq("name", name)),
  )(states);

/**
 * isStateAbbreviation
 * @param  {String}  abbreviation a (maybe valid) abbreviation
 * @return {Boolean} true if the given abbreviation is found in the list
 */
export const isStateAbbreviation = (abbreviation) =>
  R.compose(
    R.complement(R.isNil), // isNotNil
    R.find(R.propEq("abbreviation", abbreviation)),
  )(states);
