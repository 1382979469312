import { oneOf, node, string } from "prop-types";
import { Link } from "gatsby";
import cx from "classnames";

import "./contentful-link.scss";

const propTypes = {
  children: node.isRequired,
  className: string,
  description: string,
  type: oneOf(["internal", "external", "media", "page"]).isRequired,
  to: string.isRequired,
};

// used also in HelpPage.jsx to handle HelpItem clicks
export const getTarget = (type) => {
  const targets = {
    internal: "",
    page: "",
    external: "_blank",
    media: "_blank",
  };

  return targets[type] || "";
};

const ContentfulLink = (props) => {
  const { children, className, to, type, description, ...otherProps } = props;

  // don't render the link if it doesn't go anywhere
  if (!to) {
    return null;
  }

  // depending on the the kind of link, render a gatsy link or an <a>
  return getTarget(type) !== "_blank" ? (
    <Link
      to={to}
      className={cx("contentful-link", `contentful-link--${type}`, className)}
      title={description || null}
      {...otherProps}
    >
      <span>{children}</span>
    </Link>
  ) : (
    <a
      href={to}
      target="_blank"
      rel="noopener noreferrer"
      className={cx("contentful-link", className)}
      title={description || null}
      aria-label={
        type === "external"
          ? `${
              children.props ? children.props.children : children
            }. Opens in a new window.`
          : children
      }
      {...otherProps}
    >
      {/* div for flex so the svg is always to the right */}
      <span className="contentful-link__block">
        {" "}
        <span>{children}</span>
      </span>
      {type === "media" ? (
        <svg width="10" height="14" viewBox="0 0 10 14">
          <g fill="currentColor">
            <path d="M4.005.19h1.99v9.91h-1.99zM.045 11.57h9.91v1.99H.045z" />
            <path d="M3.593 10.186L8.4 5.378l1.407 1.407L5 11.593z" />
            <path d="M.192 6.785l1.407-1.407 4.808 4.808L5 11.593z" />
          </g>
        </svg>
      ) : (
        type === "external" && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-external-link"
            focusable="false"
            role="img"
            aria-hidden="true"
          >
            <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
            <polyline points="15 3 21 3 21 9"></polyline>
            <line x1="10" y1="14" x2="21" y2="3"></line>
          </svg>
        )
      )}
    </a>
  );
};

ContentfulLink.propTypes = propTypes;
export default ContentfulLink;
