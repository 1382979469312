import { useEffect, useState } from "react";
import * as R from "ramda";
import cx from "classnames";
import { Link as GatsbyLink, useStaticQuery, graphql } from "gatsby";
import { bool, oneOf } from "prop-types";

import "./modal--nav.scss";
import "./header.scss";
import "./header-nav.scss";

import Logo from "./Logo.jsx";
import MapLink from "../MapScroll/MapLink.jsx";
import Modal from "../Modal/Modal.jsx";
import Social from "../Social/Social.jsx";

import { useLocation } from "@reach/router";

const propTypes = {
  color: oneOf(["white", "dark"]), // color of hamburger and logo
  highlightLogo: bool, // whether "count" should be colored
  onBanner: bool,
};

const defaultProps = {
  color: "dark",
  highlightLogo: true,
  onBanner: false,
};

const ICON_SIZE = 32;

const Header = (props) => {
  const { color, highlightLogo, onBanner } = props;

  const [isOpen, setOpen] = useState(false);

  const contentfulData = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `,
  );

  const logoLabel = R.compose(
    (title) => `${title} logo`,
    R.path(["site", "siteMetadata", "title"]),
  )(contentfulData);

  // close the menu on click (only really needed if the user clicks the link to the current page)
  const Link = (props) => (
    <GatsbyLink onClick={() => setOpen(false)} {...props} />
  );

  const location = useLocation();

  // close the menu when the user navigates
  // (will also happen when the user navigates with the back button)
  useEffect(() => {
    setOpen(false);
  }, [location.pathname]);

  const headerLogo = (
    <div className="header__logo">
      <Link to="/" aria-label={logoLabel}>
        <Logo
          height={80}
          // fallback to default (undefined)
          color={color}
          highlight={highlightLogo}
        />
      </Link>
    </div>
  );

  return (
    <header
      className={cx("header", {
        "header--on-banner": onBanner,
        "header--white": color === "white",
      })}
    >
      <div className={"header__bar container container--bleed"}>
        {headerLogo}

        <button
          type="button"
          className="header__menu-button"
          onClick={() => setOpen(true)}
          title="Navigation Menu"
        >
          Menu
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={ICON_SIZE}
            height={ICON_SIZE}
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-menu"
          >
            <line x1="1" y1="6" x2="23" y2="6"></line>
            <line x1="1" y1="12" x2="23" y2="12"></line>
            <line x1="1" y1="18" x2="23" y2="18"></line>
          </svg>
        </button>
      </div>
      <Modal
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        className="modal--nav"
      >
        <div className="header-nav container container--100">
          <div className="header__bar container container--100">
            {headerLogo}

            <button
              type="button"
              className="header__menu-button"
              onClick={() => setOpen(false)}
            >
              Close
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={ICON_SIZE}
                height={ICON_SIZE}
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-x"
              >
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </button>
          </div>

          <div className="header-nav__menu-container">
            <div className="header-nav__menu">
              <Link to="/" className="header-nav__menu-item">
                Home
              </Link>
              <MapLink
                className="header-nav__menu-item"
                onClick={() => setOpen(false)}
              >
                Progress map
              </MapLink>
              <Link to="/data-explorer" className="header-nav__menu-item">
                Data explorer
              </Link>
              <Link to="/resources" className="header-nav__menu-item">
                Resources
              </Link>
              <Link to="/help" className="header-nav__menu-item">
                Get Involved
              </Link>
              <Link to="/our-story" className="header-nav__menu-item">
                Our story
              </Link>
            </div>
          </div>
          <div className="header-nav__social">
            <Social />
          </div>
        </div>
      </Modal>
    </header>
  );
};

Header.defaultProps = defaultProps;
Header.propTypes = propTypes;
export default Header;
