import { useCallback, useEffect, useRef, useState } from "react";
import cx from "classnames";
import debounce from "lodash.debounce";
import calculatePopupOffsets from "./calculatePopupOffsets.js";

import "./popup.scss";

import { any, bool, func, number, object, oneOf, string } from "prop-types";

const propTypes = {
  isHidden: bool,
  children: any, // content of the popup
  top: number,
  left: number,

  // having "auto" width can cause bugs when the popup butts up agains the side of the
  // viewport/parent container. The triangle might not point at the correct spot...
  // use a explict width if the popup might need to adjust by flipping/nudging
  width: number,

  onXClick: func,
  onFlip: func,

  offset: number,
  className: string,
  trianglePadding: number,
  trianglePlacement: oneOf([
    "top",
    "top-start",
    "top-end",
    "right",
    "right-start",
    "right-end",
    "bottom",
    "bottom-start",
    "bottom-end",
    "left",
    "left-start",
    "left-end",
  ]),
  triangleSize: number,
  flipToContain: string,
  flipPadding: number,
  nudgeToContain: string,
  nudgePadding: number,
  selectable: bool,
  style: object,
};

const defaultProps = {
  isHidden: false,
  trianglePlacement: "bottom",
  selectable: false,
};

const Popup = (props) => {
  const {
    top,
    left,
    children,
    offset,
    trianglePadding,
    trianglePlacement,
    triangleSize = 6, // used to calculate the top value, should match css
    className,
    isHidden,
    onXClick,
    onFlip,
    width,
    flipToContain,
    flipPadding,
    nudgeToContain,
    nudgePadding,
    selectable,
    style,
  } = props;

  const popupRef = useRef();

  const cachedChildren = useCachedValue(children);
  const cachedTop = useCachedValue(top);
  const cachedLeft = useCachedValue(left);

  const [state, setState] = useState({
    top: 0,
    left: 0,
    triangleSide: trianglePlacement.split("-")[0],
    triangleOffset: 0,
  });

  const prevTriangleSide = useCachedValue(state.triangleSide);

  // if this popup is outside of it's parent, nudge it back in
  const calculatePositions = useCallback(() => {
    const offsets = calculatePopupOffsets({
      popup: popupRef.current,
      top: cachedTop,
      left: cachedLeft,
      offset,
      trianglePadding,
      trianglePlacement,
      triangleSize,
      nudgeToContain,
      nudgePadding,
      flipToContain,
      flipPadding,
    });

    // update the state with all the measurements
    setState({
      top: offsets.popupTop,
      left: offsets.popupLeft,
      triangleOffset: offsets.triangleOffset,
      triangleSide: offsets.triangleSide,
    });
  }, [
    cachedTop,
    cachedLeft,
    offset,
    trianglePadding,
    trianglePlacement,
    triangleSize,
    nudgeToContain,
    nudgePadding,
    flipToContain,
    flipPadding,
  ]);

  // attach scroll and resize listeners
  useEffect(() => {
    const debouncedRecalc = debounce(calculatePositions, 250);
    window.addEventListener("scroll", debouncedRecalc);
    window.addEventListener("resize", debouncedRecalc);

    return () => {
      window.removeEventListener("scroll", debouncedRecalc);
      window.removeEventListener("resize", debouncedRecalc);

      debouncedRecalc.cancel();
    };
  }, [calculatePositions]);

  // calculate the position when stuff changes
  useEffect(() => {
    if (!isHidden) {
      calculatePositions();
    }
  }, [calculatePositions, isHidden, width]);

  // call onFlip if we've flipped
  useEffect(() => {
    if (state.triangleSide !== prevTriangleSide && onFlip) {
      onFlip(state.triangleSide);
    }
  });

  const triangleTopBottom =
    state.triangleSide === "top" || state.triangleSide === "bottom";

  // position the triangle according to calculatePopupOffsets
  const triangleStyles = {
    top: state.triangleSide === "bottom" && "100%",
    right: state.triangleSide === "left" && "100%",
    bottom: state.triangleSide === "top" && "100%",
    left: state.triangleSide === "right" && "100%",

    transform: triangleTopBottom
      ? `translateX(${state.triangleOffset}px)` // top/bottom
      : `translateY(${state.triangleOffset}px)`, // left/right
  };

  return (
    <div
      className={cx(
        "popup",
        `popup--triangle-${state.triangleSide}`,
        className,
        {
          "popup--selectable": selectable,
          "popup--is-hidden": isHidden,
        },
      )}
      style={{
        position: "absolute",
        top: state.top,
        left: state.left,
        width,
        ...style,
      }}
      ref={popupRef}
    >
      {
        // if there is an onXClick handler, show the X
        onXClick && "x"
      }

      <div className="popup__html">{cachedChildren}</div>

      <div className="popup__triangle" style={triangleStyles}>
        <svg
          width={12}
          height={triangleSize}
          viewBox={`0 0 12 ${triangleSize}`}
        >
          <path d={`M 0,0 L 6,${triangleSize} L 12,0`} />
        </svg>
      </div>
    </div>
  );
};

Popup.propTypes = propTypes;
Popup.defaultProps = defaultProps;
export default Popup;

// useCachedValue will use the value, of the cached value if the value is undefined
// similar to usePrevious
// https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
function useCachedValue(value) {
  const ref = useRef(value);

  useEffect(() => {
    if (typeof value !== "undefined") {
      ref.current = value;
    }
  }, [value]);

  return value || ref.current;
}
