import { useEffect, useState } from "react";

export default function useWindowSize() {
  // initialize to undefined so the server render and first client render match
  // https://github.com/gatsbyjs/gatsby/issues/14601 << my issue!
  const [windowSize, setWindowSize] = useState([]);

  useEffect(() => {
    function handleResize() {
      setWindowSize([window.innerWidth, window.innerHeight]);
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
}
