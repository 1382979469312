import { checkPropTypes, number, object, oneOf, string } from "prop-types";

/**
 * calculatePopupPositions: find the top/left
 * @param {element} popup          : DOM elmenet of the popup to be placed
 * @param {number}  top            : The top coordinate of where the popup should point
 * @param {number}  left           : The left coordinate of where the popup should point
 * @param {number}  offset         : an offet to be added to top/bottom or left/right
 * @param {number}  trianglePadding: distance from triangle edge to popup edge when aligned start/end
 * @param {string}  trianglePlacement : "top", "top-start", "top-end", "right", "right-start", "right-end", "bottom", "bottom-start", "bottom-end", "left", "left-start", "left-end"
 * @param {number}  triangleSize   : used to calculate the position
 * @param {boolean} flipToContain  : "parent", "viewport", or a parent selector. will flip the popup if it goes outside the container
 *                                   undefined will not flip at all
 * @param {number}  flipPadding    : amount the popup should be away from the edge when flipped
 * @param {string}  nudgeToContain : "parent", viewport", or a parent selector. will nudge the popup to stay in the container
 *                                   undefined will not nudge at all
 * @param {number}  nudgePadding   : amount the popup should be away from the edge when nudged
 * @return {object}  {
 *     relativeTop    : with no offset adjustment, the popup should go here, based on triangleSide, relative to parent
 *     relativeLeft   : ^^
 *     adjustedTop    : with adjustment from nudging or flipping
 *     adjustedLeft   : ^^
 *     overflow       : "top", "right", "bottom", "left".  Positive numbers are overflows
 *     triangleOffset : Amount the triangle needs to move to be on the dot, relative from 50%
 *     trianglePlacement: Will be the same as passed in trianglePlacement, unless it fliped via flipToContain
 * }
 * use adjustedTop, adjustedLeft, and triangleOffset to position the popup
 */
export default function calculatePopupOffsets(props) {
  const {
    popup,
    top = 0,
    left = 0,
    offset = 0,
    trianglePadding = 12,
    trianglePlacement = "bottom",
    triangleSize,
    flipToContain,
    nudgeToContain,
    nudgePadding = 0,
    flipPadding = 0,
  } = props;

  checkPropTypes(
    {
      popup: object.isRequired,
      top: number,
      left: number,
      trianglePlacement: oneOf([
        "top",
        "top-start",
        "top-end",
        "right",
        "right-start",
        "right-end",
        "bottom",
        "bottom-start",
        "bottom-end",
        "left",
        "left-start",
        "left-end",
      ]),
      triangleSize: number.isRequired,
      flipToContain: string,
      nudgeToContain: string,
    },
    props,
    "prop",
    "calculatePopupOffsets",
  );

  // get the width and height of this popup from the DOM
  // using offsetWidth/offsetHeight instead of getBoundingClientRect because
  // offsetWidth doesn't account for scale(0.9)
  const width = popup.offsetWidth;
  const height = popup.offsetHeight;

  // eg. top-right split to "top" and "right"
  // default alignment to "center" in the case of eg, "top"
  const [triangleSide, alignment = "center"] = trianglePlacement.split("-");

  const topBottom = triangleSide === "top" || triangleSide === "bottom";
  const leftRight = triangleSide === "left" || triangleSide === "right";

  const heightOrWidth = topBottom ? width : height;

  // common calculations
  const popupOnTop = top - height - triangleSize - offset;
  const popupOnBottom = top + triangleSize + offset;
  const popupOnLeft = left - width - triangleSize - offset;
  const popupOnRight = left + triangleSize + offset;

  // amount the triangle needs to be transformed to point to the right place
  const triangleOffset =
    alignment === "start"
      ? triangleSize + trianglePadding
      : alignment === "end"
      ? heightOrWidth - (triangleSize + trianglePadding)
      : heightOrWidth * 0.5;

  // calculate where the top of the popup should be based on top/left
  const relativeTop =
    triangleSide === "bottom"
      ? popupOnTop
      : triangleSide === "top"
      ? popupOnBottom
      : top - triangleOffset; //  left or right

  const relativeLeft =
    triangleSide === "right"
      ? popupOnLeft
      : triangleSide === "left"
      ? popupOnRight
      : left - triangleOffset; // top or bottom

  const realRight = relativeLeft + width;
  const realBottom = relativeTop + height;

  // get a bounding rect relative to the viewport
  const getContainerRect = (value) => {
    if (value === "viewport") {
      return {
        top: 0,
        right: window.innerWidth,
        bottom: window.innerHeight,
        left: 0,
        width: window.innerWidth,
        height: window.innerHeight,
      };
    } else if (value === "parent") {
      return popup.parentNode.getBoundingClientRect();
    } else if (typeof value === "string") {
      const el = popup.closest(value);
      return el ? el.getBoundingClientRect() : null;
    } else {
      return null;
    }
  };

  const nudgeContainerRect = getContainerRect(nudgeToContain);
  const flipContainerRect = getContainerRect(flipToContain);

  const horizontalContainerRect = topBottom
    ? nudgeContainerRect
    : flipContainerRect;
  const verticalContainerRect = leftRight
    ? nudgeContainerRect
    : flipContainerRect;

  const horizontalPadding = topBottom ? nudgePadding : flipPadding;
  const verticalPadding = leftRight ? nudgePadding : flipPadding;

  const parentRect = popup.parentNode.getBoundingClientRect();

  // calculate what getBoundingClientRect would be for the relative values (relative to the parent)
  const popupRect = {
    top: parentRect.top + relativeTop - verticalPadding,
    right: parentRect.left + realRight + horizontalPadding,
    bottom: parentRect.top + realBottom + verticalPadding,
    left: parentRect.left + relativeLeft - horizontalPadding,
    width,
    height,
  };

  // calcluate how much the popup goes outside the containers (positive number are outside)
  const overflow = {
    top: verticalContainerRect
      ? verticalContainerRect.top - popupRect.top
      : -1 * popupRect.top,
    left: horizontalContainerRect
      ? horizontalContainerRect.left - popupRect.left
      : -1 * popupRect.left,
    bottom: verticalContainerRect
      ? popupRect.bottom - verticalContainerRect.bottom
      : -1 * (parentRect.height - (parentRect.bottom - window.innerHeight)),
    right: horizontalContainerRect
      ? popupRect.right - horizontalContainerRect.right
      : -1 * (parentRect.width - (parentRect.right - window.innerWidth)),
  };

  // calculate where the popup should go
  // start with adjustedLeft as relativeLeft before nudging
  let adjustedTop = relativeTop;
  let adjustedLeft = relativeLeft;
  let adjustedTriangleOffset = triangleOffset;
  let newTriangleSide = triangleSide; // make a copy of this so we can override it if it flips

  // don't nudge the popup too far if the point the popup is supposed to be
  // pointing at is off the screen
  function getTriangleOffset(overflow) {
    // triangle is 2x1, triangleSize is the 1
    const min = triangleSize + trianglePadding;
    const max = heightOrWidth - min;

    const offset = triangleOffset + overflow;

    return offset < min ? min : offset > max ? max : offset;
  }

  // if there is an overflow on the right, adjust the popup and triangle position
  if (overflow.right > 0) {
    if (topBottom) {
      adjustedLeft = relativeLeft - overflow.right;
      adjustedTriangleOffset = getTriangleOffset(overflow.right);
    }

    // for left, flip the popup
    else if (triangleSide === "left" && flipToContain) {
      newTriangleSide = "right";
      adjustedLeft = popupOnLeft;
    }
  }

  // if there is an overflow on the left, adjust the popup and triangle position
  if (overflow.left > 0) {
    if (topBottom) {
      adjustedLeft = relativeLeft + overflow.left;
      adjustedTriangleOffset = getTriangleOffset(-overflow.left);
    }

    // for right, flip the popup
    else if (triangleSide === "right" && flipToContain) {
      newTriangleSide = "left";
      adjustedLeft = popupOnRight;
    }
  }

  // if there is an overflow on the bottom
  if (overflow.bottom > 0) {
    // for left/right, butt the popup against the bottom
    if (leftRight) {
      adjustedTop = relativeTop - overflow.bottom;
      adjustedTriangleOffset = getTriangleOffset(overflow.bottom);
    }

    // for top, flip the popup
    else if (triangleSide === "top" && flipToContain) {
      newTriangleSide = "bottom";
      adjustedTop = popupOnTop;
    }
  }

  // if there is an overflow on the top
  if (overflow.top > 0) {
    if (leftRight) {
      adjustedTop = relativeTop + overflow.top;
      adjustedTriangleOffset = getTriangleOffset(-overflow.top);
    }

    // for bottom, flip the popup
    else if (triangleSide === "bottom" && flipToContain) {
      newTriangleSide = "top";
      adjustedTop = popupOnBottom;
    }
  }

  const popupLeft =
    (topBottom && nudgeToContain) || (leftRight && flipToContain)
      ? adjustedLeft
      : relativeLeft;

  const popupTop =
    (leftRight && nudgeToContain) || (topBottom && flipToContain)
      ? adjustedTop
      : relativeTop;

  // return all the measurements
  return {
    popupLeft,
    popupTop,
    triangleOffset: nudgeToContain ? adjustedTriangleOffset : triangleOffset,
    trianglePlacement: flipToContain
      ? `${newTriangleSide}-${alignment}`
      : trianglePlacement,
    triangleSide: flipToContain ? newTriangleSide : triangleSide,
  };
}
