import React, { useEffect } from "react";
import * as R from "ramda";
import { bool, node, shape, string } from "prop-types";

import "../../scss/global.scss";
import "./footer.scss";
import lcchrLogo from "./logo-lcef-big@2x.png";

import { extractRichText } from "../../common/contentful-extract.jsx";

import Header from "./Header.jsx";
import Head from "./Head.jsx";
import { Link, useStaticQuery, graphql } from "gatsby";
import Logo from "./Logo.jsx";
import MapLink from "../MapScroll/MapLink.jsx";

const propTypes = {
  children: node.isRequired,
  className: string,
  highlightLogo: bool,
  // topHeroBanner to adjust the logo color based on the banner background
  topHeroBanner: shape({
    backgroundSize: string,
  }),
};

const Page = (props) => {
  const { children, className, topHeroBanner, highlightLogo } = props;

  const contentfulData = useStaticQuery(graphql`
    {
      contentfulGlobal {
        copyright {
          raw
          references {
            ...Link
          }
        }
      }
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const { copyright, logoLabel } = R.applySpec({
    copyright: R.compose(
      extractRichText,
      R.path(["contentfulGlobal", "copyright"]),
    ),
    logoLabel: R.compose(
      (title) => `${title} logo`,
      R.path(["site", "siteMetadata", "title"]),
    ),
  })(contentfulData);

  useEffect(() => {
    if (!document.querySelector("#adformEmbed")) {
      window._adftrack = Array.isArray(window._adftrack)
        ? window._adftrack
        : window._adftrack
        ? [window._adftrack]
        : [];
      window._adftrack.push({
        HttpHost: "a2.adform.net",
        pm: 2391675,
        divider: encodeURIComponent("|"),
        pagename: encodeURIComponent("AccountableNow - Retargeting"),
      });
      (function () {
        var s = document.createElement("script");
        s.type = "text/javascript";
        s.async = true;
        s.id = "adformEmbed";
        s.src = "https://s2.adform.net/banners/scripts/st/trackpoint-async.js";
        var x = document.getElementsByTagName("script")[0];
        x.parentNode.insertBefore(s, x);
      })();
    }
  }, []);

  return (
    <React.Fragment>
      <Head {...props} />
      <Header
        color={topHeroBanner ? "white" : "dark"}
        // don't highlight the logo if the background is an image, ie, use all white
        highlightLogo={
          highlightLogo || R.prop("backgroundSize", topHeroBanner) !== "cover"
        }
        onBanner={!R.isNil(topHeroBanner)}
      />
      <main className={className}>{children}</main>
      <footer>
        <div className="container container--100 footer__container">
          <Link to="/" className="footer__logo footer__accountable-now-logo">
            <Logo
              height={85}
              width={238}
              highlight={true}
              aria-label={logoLabel}
            />
          </Link>

          <div className="footer__nav">
            <div className="footer__nav-link">
              <MapLink className="btn btn--text">Progress map</MapLink>
            </div>
            <div className="footer__nav-link">
              <Link to="/data-explorer" className="btn btn--text">
                Data explorer
              </Link>
            </div>
            <div className="footer__nav-link">
              <Link to="/resources" className="btn btn--text">
                Resources
              </Link>
            </div>
            <div className="footer__nav-link">
              <Link to="/help" className="btn btn--text">
                Get Involved
              </Link>
            </div>
            <div className="footer__nav-link">
              <Link to="/our-story" className="btn btn--text">
                Our story
              </Link>
            </div>
          </div>

          <div className="hr">
            <hr />
          </div>

          <a href="https://civilrights.org/" className="footer__logo">
            <img
              width="200"
              height="75"
              src={lcchrLogo}
              className="footer__lcchr-img"
              alt="lcchr logo"
            />
          </a>
          <div className="footer__fine-print">{copyright}</div>
        </div>
      </footer>
      <noscript>
        <p style={{ margin: 0, padding: 0, border: 0 }}>
          <img
            src="https://a2.adform.net/Serving/TrackPoint/?pm=2391675&ADFPageName=AccountableNow%20-%20Retargeting&ADFdivider=|"
            width="1"
            height="1"
            alt=""
          />
        </p>
      </noscript>
    </React.Fragment>
  );
};

Page.propTypes = propTypes;
export default Page;
