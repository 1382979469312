import * as R from "ramda";

// Element -> Number
export function getOffsetTop(element) {
  if (!element) {
    return 0;
  }

  const { top } = element.getBoundingClientRect();
  const scrollTop = getScrollTop();
  return top + scrollTop;
}

// scroll the center of the element to the center of the screen
export function scrollToCenter(element) {
  if (!element) {
    return;
  }

  const { top, height } = element.getBoundingClientRect();
  const scrollCenter =
    top + height / 2 + getScrollTop() - window.innerHeight / 2;

  scrollTo(scrollCenter);
}

// String -> Number
const getOffsetTopById = (id) => {
  try {
    return R.compose(
      getOffsetTop,
      (id) => document.querySelector(`#${id}`),
      R.replace("#", ""),
    )(id);
  } catch (e) {
    // probably a bad id
  }
};

// cross browser schinanigans
export function getScrollTop() {
  return document.documentElement.scrollTop || document.body.scrollTop;
}

export function scrollTo(top) {
  // Check for IE 11
  if ("scrollBehavior" in document.documentElement.style) {
    window.scroll({
      top,
      left: 0,
      behavior: "smooth",
    });
  } else {
    window.scroll(0, top);
  }
}

export function scrollToId(id) {
  scrollTo(getOffsetTopById(id));
}

export function scrollToElement(element) {
  scrollTo(getOffsetTop(element));
}
