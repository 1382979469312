export const facebook = {
  id: "facebook",
  url: "https://www.facebook.com/civilandhumanrights",
  icon: (
    <svg width="12" height="24" viewBox="0 0 12 24">
      <path
        fill="currentColor"
        fillRule="nonzero"
        d="M9.734 3.954h2.174V.168C11.533.116 10.243 0 8.741 0 5.606 0 3.459 1.972 3.459 5.595V8.93H0v4.233h3.459v10.651h4.24v-10.65h3.32l.527-4.233H7.699V6.015c0-1.223.33-2.06 2.035-2.06z"
      />
    </svg>
  ),
};

export const twitter = {
  id: "twitter",
  url: "https://twitter.com/civilrightsorg",
  icon: (
    <svg width="24" height="20" viewBox="0 0 24 20">
      <path
        fill="currentColor"
        d="M23.954 2.569a10 10 0 01-2.825.775A4.958 4.958 0 0023.292.621c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.691 6.094 4.066 4.13 1.64 1.161a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.061a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.937 4.937 0 004.604 3.417 9.868 9.868 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.054 0 13.999-7.496 13.999-13.986 0-.209 0-.42-.015-.63a9.936 9.936 0 002.46-2.548l-.047-.02z"
      />
    </svg>
  ),
};

export const instagram = {
  id: "instagram",
  url: "https://www.instagram.com/civilrightsorg",
  icon: (
    <svg width="24" height="24" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        fillRule="nonzero"
        d="M12 0C8.74 0 8.333.015 7.053.072 5.775.132 4.905.333 4.14.63a5.876 5.876 0 00-2.126 1.384A5.855 5.855 0 00.63 4.14C.333 4.905.131 5.775.072 7.053.012 8.333 0 8.74 0 12s.015 3.667.072 4.947c.06 1.277.261 2.148.558 2.913a5.885 5.885 0 001.384 2.126A5.868 5.868 0 004.14 23.37c.766.296 1.636.499 2.913.558C8.333 23.988 8.74 24 12 24s3.667-.015 4.947-.072c1.277-.06 2.148-.262 2.913-.558a5.898 5.898 0 002.126-1.384 5.86 5.86 0 001.384-2.126c.296-.765.499-1.636.558-2.913.06-1.28.072-1.687.072-4.947s-.015-3.667-.072-4.947c-.06-1.277-.262-2.149-.558-2.913a5.89 5.89 0 00-1.384-2.126A5.847 5.847 0 0019.86.63c-.765-.297-1.636-.499-2.913-.558C15.667.012 15.26 0 12 0zm0 2.16c3.203 0 3.585.016 4.85.071 1.17.055 1.805.249 2.227.415.562.217.96.477 1.382.896.419.42.679.819.896 1.381.164.422.36 1.057.413 2.227.057 1.266.07 1.646.07 4.85 0 3.204-.015 3.585-.074 4.85-.061 1.17-.256 1.805-.421 2.227a3.81 3.81 0 01-.899 1.382 3.744 3.744 0 01-1.38.896c-.42.164-1.065.36-2.235.413-1.274.057-1.649.07-4.859.07-3.211 0-3.586-.015-4.859-.074-1.171-.061-1.816-.256-2.236-.421a3.716 3.716 0 01-1.379-.899 3.644 3.644 0 01-.9-1.38c-.165-.42-.359-1.065-.42-2.235-.045-1.26-.061-1.649-.061-4.844 0-3.196.016-3.586.061-4.861.061-1.17.255-1.814.42-2.234.21-.57.479-.96.9-1.381.419-.419.81-.689 1.379-.898.42-.166 1.051-.361 2.221-.421 1.275-.045 1.65-.06 4.859-.06l.045.03zm0 3.678a6.162 6.162 0 100 12.324 6.162 6.162 0 100-12.324zM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm7.846-10.405a1.441 1.441 0 01-2.88 0 1.44 1.44 0 012.88 0z"
      />
    </svg>
  ),
};

export const snapChat = {
  id: "snapchat",
  icon: (
    <svg width="24" height="23" viewBox="0 0 24 23">
      <g fill="none" fillRule="evenodd" transform="translate(-112 -1)">
        <path
          fill="currentColor"
          fillRule="nonzero"
          d="M124.206 1.001c.99 0 4.347.276 5.93 3.821.529 1.193.403 3.219.299 4.847l-.003.06c-.012.18-.022.345-.03.51.075.045.203.09.4.09.3-.016.66-.12 1.034-.301a.996.996 0 01.464-.104c.182 0 .359.029.509.09.45.149.734.479.734.838.015.449-.39.839-1.213 1.168-.09.029-.21.075-.344.119-.45.135-1.14.36-1.333.81-.09.224-.061.524.12.868l.015.015c.06.136 1.526 3.475 4.79 4.014.256.044.436.27.42.509a.606.606 0 01-.044.225c-.24.569-1.273.988-3.146 1.271-.06.091-.12.375-.164.57-.03.179-.074.36-.134.553-.076.271-.27.405-.555.405h-.03a3.19 3.19 0 01-.538-.074 6.034 6.034 0 00-1.273-.135c-.3 0-.6.015-.913.074-.6.104-1.123.464-1.723.884-.853.599-1.826 1.288-3.294 1.288-.06 0-.12-.015-.18-.015h-.15c-1.467 0-2.426-.675-3.278-1.288-.6-.42-1.107-.779-1.707-.884a6.623 6.623 0 00-.928-.074c-.54 0-.958.089-1.272.149a2.879 2.879 0 01-.54.074c-.374 0-.523-.224-.583-.42-.061-.192-.09-.389-.135-.567-.046-.181-.105-.494-.166-.57-1.918-.222-2.95-.642-3.19-1.226a.6.6 0 01-.054-.225.496.496 0 01.42-.509c3.264-.54 4.73-3.879 4.79-4.02l.017-.029c.18-.345.224-.645.119-.869-.195-.434-.884-.658-1.332-.809a2.233 2.233 0 01-.346-.119c-1.107-.435-1.257-.93-1.197-1.273.09-.479.674-.793 1.168-.793.146 0 .27.029.383.074.42.194.789.3 1.104.3.234 0 .384-.06.465-.105l-.046-.569c-.098-1.626-.225-3.651.307-4.837 1.539-3.527 4.886-3.797 5.874-3.797l.419-.015h.06v.001z"
        />
      </g>
    </svg>
  ),
};
