import React, { useCallback, useContext, useMemo, useRef } from "react";
import {} from "prop-types";
import { getOffsetTop, scrollTo } from "../../common/scrollHelpers.js";

const mapScrollContext = React.createContext();

export function useMapScroll() {
  return useContext(mapScrollContext);
}

/**
 * MapScrollProvider
 * Lifting the mapHolderRef and scrollMapInToView to context because we need to
 * be able to call scrollMapInToView from the footer/navigation
 */
export const MapScrollProvider = (props) => {
  // top of info panel
  const mapPanelRef = useRef();

  // top of map, not necessarily the top of the info panel because the map is sticky
  const mapHolderRef = useRef();

  // scroll to the top of the info panel
  const scrollToInfoPanel = useCallback(() => {
    if (mapPanelRef && mapPanelRef.current) {
      scrollTo(getOffsetTop(mapPanelRef.current));
    }
  }, [mapPanelRef]);

  // scroll to nudge the map fully in to view
  const scrollMapInToView = useCallback(() => {
    if (mapHolderRef && mapHolderRef.current) {
      const { top } = mapHolderRef.current.getBoundingClientRect();

      // the container is sticky, so if the the top is not 0, then it's
      // out of the viewport either above or below.
      if (top !== 0) {
        scrollTo(getOffsetTop(mapHolderRef.current));
      }
    }
  }, [mapHolderRef]);

  const context = useMemo(() => {
    return { mapHolderRef, mapPanelRef, scrollMapInToView, scrollToInfoPanel };
  }, [scrollMapInToView, scrollToInfoPanel]);

  return <mapScrollContext.Provider value={context} {...props} />;
};
