import { node, string } from "prop-types";
import cx from "classnames";

import "./container.scss";

const propTypes = {
  as: string,
  className: string,
  left: node,
  right: node,
};

const Container3070 = (props) => {
  const { left, right, className, ...otherProps } = props;

  return (
    <div
      className={cx("container container--30-70", className)}
      {...otherProps}
    >
      <div className="container__col container__col--30 container__col--30-left">
        {left}
      </div>
      <div className="container__col container__col--70 container__col--30-right">
        {right}
      </div>
    </div>
  );
};

Container3070.propTypes = propTypes;
export default Container3070;
