import {} from "prop-types";

import "./social-network.scss";

import { facebook, twitter, instagram } from "./networks.jsx";

const propTypes = {};

const Social = (props) => {
  const networks = [facebook, twitter, instagram];

  return (
    <div className="social-network">
      {networks.map((network) => (
        <div className="social-network__icon-container" key={network.id}>
          <a href={network.url} target="_blank" rel="noopener noreferrer">
            {network.icon}
          </a>
        </div>
      ))}
    </div>
  );
};

Social.propTypes = propTypes;
export default Social;
