import { bool, node, string } from "prop-types";
import cx from "classnames";
import useWindowSize from "../../common/useWindowSize.js";

import "./container.scss";

const propTypes = {
  className: string,
  left: node,
  right: node,
  swapStack: bool,
};

const defaultProps = {
  swapStack: false,
};

const BP_LARGE_MIN = 910; // matches $bp-large-min in _breakpoints.scss

const Container5050 = (props) => {
  const { left, right, className, swapStack, ...otherProps } = props;

  const [windowWidth] = useWindowSize();

  return (
    <div
      className={cx("container container--50-50", className)}
      {...otherProps}
    >
      <div className="container__col container__col--50 container__col--50-left">
        {windowWidth < BP_LARGE_MIN && swapStack ? right : left}
      </div>
      <div className="container__col container__col--50 container__col--50-right">
        {windowWidth < BP_LARGE_MIN && swapStack ? left : right}
      </div>
    </div>
  );
};

Container5050.defaultProps = defaultProps;
Container5050.propTypes = propTypes;
export default Container5050;
