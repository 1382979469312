import * as R from "ramda";
import { navigate, useMatch } from "@reach/router"; // reach router ships with gatsby
import { withPrefix } from "gatsby";

import { isStateAbbreviation } from "./states.js";

// either state or dept
export const navigateToMapEntity = (id) => {
  // not sure why we need requestAnimationFrame, but useMapUrlId was getting a stale
  // id when redirecting to the map page via navigateToMapEntity() << with empty id
  window.requestAnimationFrame(() => {
    navigate(getMapEntityUrl(id));
  });
};

export const getMapEntityUrl = (id = "") => {
  return withPrefix(`/map/${id}`);
};

export const MAP_STATE = "State";
export const MAP_US = "US";
export const MAP_DEPARTMENT = "Department";

export const getMapIdType = (id) => {
  return R.cond([
    // US will scroll the map and center, etc
    [R.anyPass([R.isNil, R.isEmpty, R.equals("US")]), () => MAP_US],
    [isStateAbbreviation, () => MAP_STATE],
    [R.T, () => MAP_DEPARTMENT],
  ])(id);
};

/**
 * useMapUrlId
 * React hook to return the id of the selected state or deptartment on the map
 * page from the url
 * eg. /map/MA009300 would return MA009300
 * eg. /map/MA would return MA
 * eg. /map would return null
 * @return {String|Null} the id or null if there is nothing selected
 */
export const useMapUrlId = () => {
  const match = useMatch(withPrefix("/map/:id"));

  return R.prop("id", match);
};
