import { bool } from "prop-types";

const propTypes = {
  highlight: bool,
};

const HIGHLIGHT_COLOR = "#2ea7aa";

const Logo = (props) => {
  const { highlight, ...otherProps } = props;

  return (
    <svg viewBox="0 0 295.56 105.76" {...otherProps}>
      <g>
        <path
          d="M88.52 68.43v11.78h-5.76V69.27c0-2.38-1.5-3.49-3.34-3.49-2.11 0-3.57 1.23-3.57 3.95v10.48H70.1V61.02h5.76v1.8c1.04-1.42 2.95-2.34 5.49-2.34 3.87 0 7.17 2.77 7.17 7.95zM90.62 70.62c0-5.72 4.53-10.13 10.17-10.13s10.17 4.41 10.17 10.13-4.53 10.13-10.17 10.13-10.17-4.42-10.17-10.13zm14.58 0c0-2.72-1.92-4.53-4.41-4.53s-4.41 1.8-4.41 4.53 1.92 4.53 4.41 4.53c2.49-.01 4.41-1.81 4.41-4.53zM139.77 61.02l-6.14 19.19h-5.37l-3.07-10.25-3.07 10.25h-5.37l-6.14-19.19h6.14l2.76 10.28 2.99-10.28h5.37l2.99 10.28 2.76-10.28h6.15zM74.5 32.62v19.19h-5.76V50c-1.27 1.46-3.15 2.34-5.72 2.34-5.03 0-9.17-4.41-9.17-10.13s4.14-10.13 9.17-10.13c2.57 0 4.45.88 5.72 2.34v-1.8h5.76zm-5.76 9.59c0-2.88-1.92-4.68-4.57-4.68s-4.57 1.8-4.57 4.68 1.92 4.68 4.57 4.68 4.57-1.8 4.57-4.68zM76.81 42.21c0-5.72 4.34-10.13 10.17-10.13 3.72 0 7.02 1.96 8.67 4.91l-5.03 2.92c-.65-1.34-2.03-2.15-3.72-2.15-2.49 0-4.34 1.8-4.34 4.45 0 2.65 1.84 4.45 4.34 4.45 1.69 0 3.11-.81 3.72-2.15l5.03 2.88c-1.65 2.99-4.91 4.95-8.67 4.95-5.83 0-10.17-4.41-10.17-10.13z"
          fill="currentColor"
        />
        <path
          d="M96.03 42.21c0-5.72 4.34-10.13 10.17-10.13 3.72 0 7.02 1.96 8.67 4.91l-5.03 2.92c-.65-1.34-2.03-2.15-3.72-2.15-2.49 0-4.34 1.8-4.34 4.45 0 2.65 1.84 4.45 4.34 4.45 1.69 0 3.11-.81 3.72-2.15l5.03 2.88c-1.65 2.99-4.91 4.95-8.67 4.95-5.83 0-10.17-4.41-10.17-10.13zM115.24 42.21c0-5.72 4.53-10.13 10.17-10.13s10.17 4.41 10.17 10.13-4.53 10.13-10.17 10.13-10.17-4.41-10.17-10.13zm14.58 0c0-2.72-1.92-4.53-4.41-4.53s-4.41 1.8-4.41 4.53 1.92 4.53 4.41 4.53c2.5 0 4.41-1.8 4.41-4.53zM156.1 32.62v19.19h-5.76V50c-1.04 1.42-2.95 2.34-5.49 2.34-3.88 0-7.18-2.76-7.18-7.94V32.62h5.76v10.94c0 2.38 1.5 3.49 3.34 3.49 2.11 0 3.57-1.23 3.57-3.95V32.62h5.76zM177.86 40.03v11.78h-5.76V40.87c0-2.38-1.5-3.49-3.34-3.49-2.11 0-3.57 1.23-3.57 3.95v10.48h-5.76V32.62h5.76v1.8c1.04-1.42 2.95-2.34 5.49-2.34 3.88 0 7.18 2.76 7.18 7.95zM188 38.14v6.79c0 1.65 1.42 1.8 3.95 1.65v5.22c-7.52.77-9.71-1.5-9.71-6.87v-6.79h-3.07v-5.53h3.07v-3.65l5.76-1.73v5.37h3.95v5.53H188z"
          fill={highlight ? HIGHLIGHT_COLOR : "currentColor"}
        />
        <path
          d="M214.13 32.62v19.19h-5.76V50c-1.27 1.46-3.15 2.34-5.72 2.34-5.03 0-9.17-4.41-9.17-10.13s4.14-10.13 9.17-10.13c2.57 0 4.45.88 5.72 2.34v-1.8h5.76zm-5.76 9.59c0-2.88-1.92-4.68-4.57-4.68s-4.57 1.8-4.57 4.68 1.92 4.68 4.57 4.68 4.57-1.8 4.57-4.68zM238.13 42.21c0 5.72-4.14 10.13-9.17 10.13-2.57 0-4.45-.88-5.72-2.34v1.8h-5.76V24.94h5.76v9.48c1.27-1.46 3.15-2.34 5.72-2.34 5.03 0 9.17 4.42 9.17 10.13zm-5.75 0c0-2.88-1.92-4.68-4.57-4.68s-4.57 1.8-4.57 4.68 1.92 4.68 4.57 4.68 4.57-1.8 4.57-4.68zM240.5 23.79h5.76V51.8h-5.76V23.79zM259.26 47.2c1.57 0 2.92-.61 3.68-1.46l4.61 2.65c-1.88 2.61-4.72 3.95-8.37 3.95-6.56 0-10.63-4.41-10.63-10.13s4.14-10.13 10.21-10.13c5.6 0 9.75 4.34 9.75 10.13 0 .81-.08 1.57-.23 2.3h-13.62c.73 2 2.49 2.69 4.6 2.69zm3.53-6.98c-.61-2.23-2.34-3.03-4.07-3.03-2.19 0-3.65 1.07-4.18 3.03h8.25z"
          fill="currentColor"
        />
        <g>
          <path
            d="M55.07 53.08L41.98 64.87l.03.03 4.07 3.68 8.94-8.05 8.72 8.06 4.09-3.68.01-.02z"
            fill={highlight ? HIGHLIGHT_COLOR : "currentColor"}
          />
        </g>
        <g>
          <path
            d="M52.3 75.43h5.5v4.78h-5.5zM52.3 56.26h5.5V71.9h-5.5z"
            fill={highlight ? HIGHLIGHT_COLOR : "currentColor"}
          />
        </g>
        <g>
          <path
            d="M39.76 62.85L26.67 51.06l.03-.03 4.07-3.67 8.94 8.05 8.93-8.26 4.06 3.75-.17.14z"
            fill="currentColor"
          />
        </g>
        <g>
          <path
            d="M36.98 32.59h5.5v4.78h-5.5zM36.98 40.9h5.5v15.64h-5.5z"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
};

Logo.propTypes = propTypes;
export default Logo;
